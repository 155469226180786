<template>
  <div :class="$style.section">
    <div :class="$style.col">
      <h2 :class="$style.title">{{ $t('preferences') }}</h2>
      <div :class="$style.wrapper">
        <toggle-input :text="$t('settings.notifications.toggle.push')" :class="$style.toggle" />
        <toggle-input
          :text="$t('settings.notifications.toggle.email')"
          :class="$style.toggle"
          active
        />
        <toggle-input :text="$t('settings.notifications.toggle.site')" :class="$style.toggle" />
        <toggle-input :text="$t('settings.notifications.toggle.toast')" :class="$style.toggle" />
        <toggle-input :text="$t('settings.notifications.toggle.sms')" :class="$style.toggle" />
      </div>
    </div>
    <div :class="$style.col">
      <h2 :class="$style.title">{{ $t('other') }}</h2>
      <div :class="$style.wrapper">
        <toggle-input text="Telegram Bot" :class="$style.toggle" active />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapMutations } from 'vuex'
import ToggleInput from '@elements/inputs/list/ToggleInput.vue'

export default Vue.extend({
  name: 'Notifications',
  components: { ToggleInput },
  created() {
    this.setPage({
      name: 'notifications',
      translation: {
        name: 'settings.notifications.title',
      },
    })
  },
  methods: {
    ...mapMutations(['setPage']),
  },
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  grid-auto-rows: min-content;
  width: 100%;
  height: 100%;
  padding: 17px 0;
  background-color: $shadow;
  row-gap: 17px;
}

.col {
  display: grid;
  row-gap: 17px;
  width: 100%;
  height: 100%;
}

.title {
  padding: 0 17px;
  color: $secondary;
  font-weight: 500;
  font-size: 15px;
}

.wrapper {
  width: 100%;
}

.toggle {
  padding: 17px 15px;
  background-color: white;
  box-shadow: inset 0 -1px 0 $secondary-light;

  &:first-child {
    box-shadow: inset 0 -1px 0 $secondary-light, inset 0 1px 0 $secondary-light;
  }
}
</style>
