// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Notifications_section_3QEho{grid-auto-rows:min-content;padding:17px 0;background-color:#f8f8f8}.Notifications_col_1Qaah,.Notifications_section_3QEho{display:grid;width:100%;height:100%;row-gap:17px}.Notifications_title_10BFs{padding:0 17px;color:var(--supster-secondary);font-weight:500;font-size:15px}.Notifications_wrapper_3L0vu{width:100%}.Notifications_toggle_rZ3v0{padding:17px 15px;background-color:#fff;box-shadow:inset 0 -1px 0 #e5e3e3}.Notifications_toggle_rZ3v0:first-child{box-shadow:inset 0 -1px 0 #e5e3e3,inset 0 1px 0 #e5e3e3}", ""]);
// Exports
exports.locals = {
	"section": "Notifications_section_3QEho",
	"col": "Notifications_col_1Qaah",
	"title": "Notifications_title_10BFs",
	"wrapper": "Notifications_wrapper_3L0vu",
	"toggle": "Notifications_toggle_rZ3v0"
};
module.exports = exports;
